html, body, #root {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  width: 100%;
  height: 100%;
}

#coming-soon-image {
  width: 100%;
  object-fit: contain;
}